import React, {
  ChangeEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { graphql } from 'gatsby';
import { Vacancy, VacancyPaginated } from '../sdk/entities/Vacancy';
import { Layout } from '../components/layout/layout';
import VacanciesList from '../components/vacancies-list/vacancies-list';
import { Company } from '../sdk/entities/Company';
import * as classes from '../styles/templates/company.module.scss';
import { FaLinkedin } from '@react-icons/all-files/fa/FaLinkedin';
import { FaYoutube } from '@react-icons/all-files/fa/FaYoutube';
import { FaFacebook } from '@react-icons/all-files/fa/FaFacebook';
import { FiLink2 } from '@react-icons/all-files/fi/FiLink2';
import { BASE_URL } from '../config';
import axios from 'axios';
import NoVacancyAdvice from '../components/no-vacancy-advice/no-vacancy-advice';
import { Spinner } from '../components/spinner/spinner';
import { AiFillInstagram } from '@react-icons/all-files/ai/AiFillInstagram';
import { SEO } from '../components/seo/seo';
import Pagination from '../components/pagination/pagination';
import Select from '../components/selects/select/select';

export type CompanyPageProps = {
  data: {
    company: Company;
    allVacancy: {
      nodes: Vacancy[];
    };
    distinctWorkplaces: {
      group: {
        nodes: {
          workplace: {
            name: string;
            id: string;
          };
        }[];
      }[];
    };
    distinctContractModel: {
      distinct: string[];
    };
    distinctWorkField: {
      group: {
        nodes: {
          position: {
            workField: {
              name: string;
              id: string;
            };
          };
        }[];
      }[];
    };
  };
};

const CompanyPage: React.FC<CompanyPageProps> = ({ data }) => {
  const [workFieldId, setWorkFieldId] = useState('');
  const [contractModel, setContractModel] = useState('');
  const [workplaceId, setWorkplaceId] = useState('');
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [vacancies, setVacancies] = useState<VacancyPaginated>();
  const [loading, setLoading] = useState(false);
  const timeout = useRef<number>(null);

  const social = useMemo(() => {
    if (!data.company.social) return;

    return JSON.parse(data.company.social);
  }, [data.company.social]);

  const socialIcons = {
    facebook: <FaFacebook size="24px" />,
    linkedin: <FaLinkedin size="24px" />,
    instagram: <AiFillInstagram size="25px" />,
    youtube: <FaYoutube size="24px" />,
  };

  const siteLink = () => {
    if (social.site) {
      const linkWithoutProtocol = social.site.replace(/http?s:\/\/www./, '');

      return (
        <a href={social.site} target="_blank" rel="noreferrer" key={'site'}>
          <FiLink2 size="24px" /> <span>{linkWithoutProtocol}</span>
        </a>
      );
    }
  };

  const getVacancies = () => {
    setLoading(true);
    const newParams = new URLSearchParams();
    newParams.append('companyId', data.company.id);
    newParams.append('limit', '300');

    [workFieldId, contractModel, workplaceId, search, page].forEach(
      (param, index) => {
        if (param) {
          newParams.append(
            ['workFieldId', 'contractModel', 'workplaceId', 'search', 'page'][
              index
            ],
            param.toString(),
          );
        }
      },
    );

    const url = `${BASE_URL}/public/vacancies/active?${newParams.toString()}`;
    axios.get(url).then(response => {
      setVacancies(response.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    if (!workFieldId && !contractModel && !workplaceId && !search) {
      setVacancies({
        items: data.allVacancy.nodes,
      });
      return;
    }

    getVacancies();
  }, [workFieldId, contractModel, workplaceId, search, page]);

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    clearTimeout(timeout.current || undefined);

    (timeout as any).current = setTimeout(() => {
      setPage(1);
      setSearch(e.target.value);
    }, 1000);

    setSearchValue(e.target.value);
  };

  return (
    <main>
      <Layout hideSearch hideFilter hideFooter hideBreadcrumb companyHeader>
        <div className={classes.companyContent}>
          <div className={classes.header}>
            <img
              className={classes.companyLogo}
              src={data.company.image}
              alt={`Logo da empresa ${data.company.name}`}
            />

            <div className={classes.content}>
              <h1 className={classes.title}>{data.company.name}</h1>

              {social && (
                <div className={classes.links}>
                  {Object.entries(social).map(([key, value]) => {
                    if (key === 'site') return;
                    return (
                      <a
                        href={value as string}
                        target="_blank"
                        rel="noreferrer"
                        key={key}
                      >
                        {socialIcons[key as keyof typeof socialIcons]}
                      </a>
                    );
                  })}

                  {siteLink()}
                </div>
              )}
            </div>
          </div>

          {data.company.description && (
            <div className={classes.description} id="about-us">
              <h2>Conheça a nossa empresa</h2>
              <span
                dangerouslySetInnerHTML={{ __html: data.company.description }}
              />
            </div>
          )}
        </div>

        <div className={classes.vacanciesContainer} id="vacancies">
          <h2 className={classes.title}>Nossas vagas</h2>

          <div className={classes.filters}>
            <div className={classes.input}>
              <input
                type="text"
                id="q"
                placeholder="Buscar por cargo/palavra-chave"
                value={searchValue}
                onChange={e => {
                  handleChangeInput(e);
                }}
              />
            </div>

            <div className={classes.select}>
              <div className={classes.label}>Local</div>
              <Select
                id="workplaceId"
                disableEmpty
                emptyText={'Selecionar local'}
                data={data.distinctWorkplaces.group.map(item => ({
                  label: item.nodes[0].workplace.name,
                  value: item.nodes[0].workplace.id,
                }))}
                value={workplaceId}
                onChange={e => {
                  setPage(1);
                  setWorkplaceId(e.target.value);
                }}
              />
            </div>

            <div className={classes.select}>
              <div className={classes.label}>Área da vaga</div>
              <Select
                data={data.distinctWorkField.group.map(item => ({
                  label: item.nodes[0].position.workField.name,
                  value: item.nodes[0].position.workField.id,
                }))}
                value={workFieldId}
                id="workFieldId"
                onChange={e => {
                  setPage(1);
                  setWorkFieldId(e.target.value);
                }}
                disableEmpty
                emptyText={'Selecionar área'}
              />
            </div>

            <div className={classes.select}>
              <div className={classes.label}>Tipo de Contrato</div>
              <Select
                data={data.distinctContractModel.distinct.map(item => ({
                  label: item,
                  value: item,
                }))}
                id="contractModel"
                value={contractModel}
                onChange={e => {
                  setPage(1);
                  setContractModel(e.target.value);
                }}
                disableEmpty
                emptyText={'Selecionar tipo de contrato'}
              />
            </div>
          </div>

          {vacancies && vacancies.items.length > 0 ? (
            <div className={classes.vacancies}>
              <VacanciesList
                vacancies={vacancies?.items}
                utmMedium="company_page"
              />
            </div>
          ) : (
            <div className={classes.vacancies}>
              {loading ? <Spinner /> : <NoVacancyAdvice />}
            </div>
          )}

          {!loading && vacancies?.meta && (
            <Pagination
              meta={vacancies?.meta}
              handlePageChange={(page: number) => {
                setPage(page);
              }}
            />
          )}
        </div>

        <footer className={classes.footer}>
          Powered by Vendoor 2024. Todos os direitos reservados.
        </footer>
      </Layout>
    </main>
  );
};

export const query = graphql`
  query ($id: String) {
    company(_id: { eq: $id }) {
      id
      name
      image
      slug
      description
      social
    }

    allVacancy(
      sort: { createdAt: DESC }
      filter: { active: { eq: true }, companyId: { eq: $id } }
    ) {
      nodes {
        id
        _id
        name
        maxRemuneration
        confidential
        contractModel
        description
        remuneration
        remunerationModel
        slug
        position {
          slug
          workField {
            slug
          }
        }
        workplace {
          name
          address {
            city
            complement
            country
            district
            lat
            lng
            number
            postalCode
            referencePoint
            state
            street
          }
          image
        }
        officeDays
        image {
          childImageSharp {
            gatsbyImageData(
              width: 40
              height: 40
              transformOptions: { fit: COVER }
              quality: 80
            )
          }
        }
      }
    }

    distinctWorkplaces: allVacancy(
      filter: { active: { eq: true }, companyId: { eq: $id } }
    ) {
      group(field: { workplaceId: SELECT }, limit: 1) {
        nodes {
          workplace {
            name
            id
          }
        }
      }
    }

    distinctContractModel: allVacancy(
      filter: { active: { eq: true }, companyId: { eq: $id } }
    ) {
      distinct(field: { contractModel: SELECT })
    }

    distinctWorkField: allVacancy(
      filter: { active: { eq: true }, companyId: { eq: $id } }
    ) {
      group(field: { position: { workFieldId: SELECT } }, limit: 1) {
        nodes {
          position {
            workField {
              name
              id
            }
          }
        }
      }
    }
  }
`;

export const Head: React.FC<CompanyPageProps> = ({ data }) => (
  <SEO
    title={data.company.name}
    image={data.company.image}
    description={data.company.description}
    pathname={`/${data.company.slug}/`}
  />
);

export default CompanyPage;
